import React from 'react';
import './App.css';
import MainRoute from './Routes';

function App() {
	return (
		<>
			<MainRoute />
			<div className="App"></div>
		</>
	);
}

export default App;
